<template>
  <div class="word-list-wrap">
    <div class="header">话术列表({{ list.length }})</div>
    <div class="wrap">
      <router-link
        class="link-item"
        v-for="item in list"
        :key="item.id"
        :to="'/words/' + item.id"
        ><van-icon name="label-o" class="label" /><span>{{
          item.title
        }}</span></router-link
      >
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      let ret = await this.$http.get("/transcripts");
      this.list = ret;
      console.log(">>> fetch", ret);
    },
  },
};
</script>
<style lang="scss" scoped>
.word-list-wrap {
  padding: r(20);
  padding-top: r(40);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    font-size: r(60);
    line-height: r(50);
    font-weight: bold;
    margin-bottom: r(40);
    flex-shrink: 0;
  }
  .wrap {
    flex: 1;
    overflow-y: auto;
  }
  .link-item {
    display: block;
    text-decoration: none;
    font-size: r(50);
    line-height: r(60);
    color: #000;
    margin-bottom: r(20);
    word-break: break-all;
    display: flex;

    .label {
      margin-right: r(8);
      flex-shrink: 0;
      line-height: r(60);
    }
    span {
      flex-wrap: wrap;
    }
  }
}
</style>